import { useState, useEffect } from "react";
import { Frame, Loading, Header } from "../components";
import { db, auth } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import Logo from "../assets/logo2.png";
import moment from "moment";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [dados, setDados] = useState({});

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const getData = async () => {
    const qry = await getDoc(doc(db, "cangucu2025", uid));

    const data = qry.data();

    setDados(data);
    setLoading(false);
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header nome={dados?.nome} foto={dados?.urlFoto} />

      {loading && <Loading />}

      {!loading && !dados?.status && <Novo />}
      {!loading && dados?.status === "p" && <Pendente />}
      {!loading && dados?.status === "i" && <Inativo />}
      {!loading && dados?.status === "c" && <Cancelado />}
      {!loading && dados?.status === "a" && <Aprovado dados={dados} />}
      {!loading && dados?.status === "d" && <Devedor />}
    </>
  );
};

export default Home;

const Novo = () => {
  return (
    <Frame
      body={
        <div className="justify p-3">
          <p>
            Preencha seus dados para utilizar o nosso serviço de fretamento
            estudantil de Canguçu 2025.
          </p>
          <div className="alert mt-3 alert-warning">
            Cadastros realizados antes das 14:00 estão aptos para embarque no
            próximo dia útil.
          </div>

          <div className="mt-4 text-end">
            <a className="btn btn-primary" href="./form1">
              Preencha aqui
            </a>
          </div>
        </div>
      }
      title="Finalize seu cadastro"
    />
  );
};

const Pendente = () => {
  return (
    <Frame
      body={
        <div className="justify px-3">
          <p>
            Aguarde, estamos verificando a documentação enviada para liberamos
            seu cadastro.
          </p>
          <p>
            Depois de aprovado você terá acesso à sua carteirinha digital e aos
            demais menus do portal.
          </p>

          <p>Agradecemos por utilizar os nossos serviços.</p>

          <div className="alert alert-warning">
            Cadastros realizados antes das 14:00 estão aptos para embarque no
            próximo dia útil.
          </div>
        </div>
      }
      title="Cadastro em Análise"
    />
  );
};

const Aprovado = (props) => {
  const saldoMes = "saldo" + moment().format("MMYY");

  return (
    <>
      <Frame
        body={
          <div className="container-fluid">
            <div className="col-sm-6 mx-auto">
              <img className="mt-2" src={Logo} width={250} alt="logo" />
              <h5 className="mt-2 py-1 bg-success bg-gradient text-white rounded-1">
                FRETAMENTO CANGUÇU 2025
              </h5>

              <div className="mt-2">
                <QRCode size={150} value={"378|" + props.dados.uid} />
              </div>

              <img
                src={props.dados.urlFoto}
                alt="Usuário"
                width="150"
                height="150"
                className="d-inline rounded-circle mt-2"
              />

              <div className="mt-2 text-uppercase">
                <h5 className="mt-2 py-1 bg-success bg-gradient text-white rounded-1">
                  {props.dados.nome}
                </h5>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <table className="table table-sm table-borderless text-start text-capitalize m-0 w-25 fs-5">
                  <tbody>
                    <tr>
                      <td className="fw-bold azul-logo">CPF:</td>
                      <td>{props.dados.cpf}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold azul-logo">RG:</td>
                      <td>{props.dados.rg}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold azul-logo">Tipo:</td>
                      <td>{props.dados.vinculo}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold azul-logo">Saldo:</td>
                      <td>{props.dados[saldoMes] || "0"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

const Inativo = () => {
  return (
    <Frame
      body={
        <div className="justify px-3">
          <p>
            Seu cadastro em nosso serviço de fretamento de CANGUÇU está inativo.
          </p>
          <p>
            Se deseja ativá-lo novamente, entre em contato pelo WhatsApp do
            fretamento: (53) 98122-2534.
          </p>
        </div>
      }
      title="Cadastro Inativo"
    />
  );
};

const Cancelado = () => {
  return (
    <Frame
      body={
        <div className="justify px-3">
          <p>
            Seu cadastro em nosso serviço de fretamento de CANGUÇU está
            cancelado.
          </p>
          <p>
            Se deseja ativá-lo novamente, entre em contato pelo WhatsApp do
            fretamento: (53) 98122-2534.
          </p>
        </div>
      }
      title="Cadastro Cancelado"
    />
  );
};

const Devedor = () => {
  return (
    <Frame
      body={
        <div className="justify px-3">
          <p>
            Seu cadastro em nosso serviço de fretamento de CANGUÇU está com uma
            pendência financeira.
          </p>
          <p>
            Por gentileza, entre em contato conosco pelo WhatsApp: (53)
            98122-2534.
          </p>
        </div>
      }
      title="Cadastro Devedor"
    />
  );
};
